/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-11 16:50:34
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-11 17:51:32
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerDepartment = params => axios({
    url: '/api/base/system/department/listDealer',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerDepartment = params => axios({
    url: '/api/base/system/department/addDealer',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerDepartment = params => axios({
    url: '/api/base/system/department/editDealer',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerDepartment = params => axios({
    url:'/api/base/system/department/delDealer/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerDepartment = params => axios({
    url: '/api/base/system/department/selectDealerById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
