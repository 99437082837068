import { render, staticRenderFns } from "./DealerDepartmentEditModal.vue?vue&type=template&id=5f4f2cb0&scoped=true&"
import script from "./DealerDepartmentEditModal.vue?vue&type=script&lang=js&"
export * from "./DealerDepartmentEditModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f4f2cb0",
  null
  
)

export default component.exports