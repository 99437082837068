/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-11 16:50:34
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-11 17:56:35
 */
export const columns = [
{
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'left',
    ellipsis:true
},
{
  title: '编号',
  dataIndex: 'code',
  key: 'code',
  /*width:150,*/
  align:'left',
  ellipsis:true
},
{
  title: '部门主管',
  dataIndex: 'leaderName',
  key: 'leaderName',
  /*width:150,*/
  align:'left',
  ellipsis:true
},
{
    title: '是否启用',
    dataIndex: 'flagEnable',
    key: 'flagEnable',
    width:200,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'flagEnable'}
},
// {
//     title: '操作',
//     dataIndex: 'action',
//     key: 'action',
//     width:150,
//     align:'center',
//     ellipsis:true
// },

]
